import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import PsicoID from './pages/PsicoID';
import Sobre from './pages/Sobre';
import Contato from './pages/Contato';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <AnimatedRoutes />
      <Footer />
    </Router>
  );
}

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key} 
        classNames="page" 
        timeout={500} 
      >
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/psicoid" element={<PsicoID />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
