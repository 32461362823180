import React, { useState } from 'react';
import logo from '../assets/logo.png';

function Sobre() {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  document.title = "Sobre Idealizer";
  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        {!imageLoaded && <div style={styles.placeholder}></div>}
        
        <img
          src={logo}
          alt="Logo Idealizer"
          style={{
            ...styles.logo,
            opacity: imageLoaded ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
          }}
          onLoad={handleImageLoad}
        />
      </div>

      <div style={styles.introContainer}>
        <h2 style={styles.companyTitle}>Nós somos a Idealizer</h2>
        <h3 style={styles.subTitle}>Desenvolvemos soluções para facilitar e profissionalizar seu negócio!</h3>
      </div>

      <div style={styles.missionVisionValues}>
        <div style={styles.mvvItem}>
          <div style={styles.card}>
            <h4 style={styles.mvvTitle}>Missão</h4>
            <p style={styles.mvvText}>Entregar soluções tecnológicas que transformem negócios.</p>
          </div>
        </div>
        <div style={styles.mvvItem}>
          <div style={styles.card}>
            <h4 style={styles.mvvTitle}>Visão</h4>
            <p style={styles.mvvText}>Ser referência em inovação e tecnologia no mercado.</p>
          </div>
        </div>
        <div style={styles.mvvItem}>
          <div style={styles.card}>
            <h4 style={styles.mvvTitle}>Valores</h4>
            <p style={styles.mvvText}>Compromisso, Inovação, Ética e Excelência.</p>
          </div>
        </div>
      </div>

      <div style={styles.purposeContainer}>
        <div style={styles.card}>
          <h2 style={styles.purposeTitle}>Nosso propósito</h2>
          <h3 style={styles.purposeSubTitle}>Conectar pessoas e tecnologias para um futuro melhor.</h3>
          <p style={styles.purposeText}>
            Trabalhamos para que a tecnologia esteja acessível a todos, promovendo a transformação digital nas empresas e
            oferecendo soluções que realmente fazem a diferença.
          </p>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '2rem',
    marginBottom: '380px',
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: '2rem',
  },
  logo: {
    width: '250px',
    height: 'auto',
  },
  introContainer: {
    textAlign: 'center',
    marginTop: '2rem',
  },
  companyTitle: {
    fontSize: '2rem',
    color: '#fff',
  },
  subTitle: {
    fontSize: '1.2rem',
    color: '#d9d9d9',
  },
  missionVisionValues: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '2rem',
  },
  mvvItem: {
    textAlign: 'center',
    flex: '1 1 30%',
    maxWidth: '300px',
    margin: '0.5rem',
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '1.0rem',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    color: '#333',
  },
  mvvTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#333',
  },
  mvvText: {
    fontSize: '1rem',
    color: '#333',
  },
  purposeContainer: {
    marginTop: '3rem',
    textAlign: 'center',
  },
  purposeTitle: {
    fontSize: '2rem',
    color: '#000',
  },
  purposeSubTitle: {
    fontSize: '1.5rem',
    color: '#000',
  },
  purposeText: {
    fontSize: '1rem',
    color: '#000',
    marginTop: '1rem',
  },
  placeholder: {
    width: '250px',
    height: 'auto',
    backgroundColor: '#ccc',
    margin: '0 auto',
  },
};

export default Sobre;
