import React from 'react';
import { Link } from 'react-router-dom'; 

function Footer() {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <div style={styles.privacy}>
          <Link to="/politica-privacidade" style={styles.link}>Política de Privacidade</Link> {}
          <p style={styles.email}>contato@idealizer.com.br</p>
        </div>
        <p style={styles.text}>CNPJ: 57.325.737/0001-00</p>
        <p style={styles.text}>&copy; 2024 Idealizer LTDA. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

const styles = {
  footer: {
    padding: '1rem',
    textAlign: 'center',
    backgroundColor: '#333',
    color: '#fff',
    width: '100%',
    position: 'relative',
    marginTop: '30px',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  privacy: {
    marginBottom: '1rem',
  },
  link: {
    margin: '0.5rem 0',
    fontSize: '0.9rem',
    color: '#22c55e',
    textDecoration: 'none',
  },
  email: {
    margin: '0.5rem 0',
    fontSize: '0.9rem',
  },
  text: {
    margin: '0.5rem 0',
    fontSize: '0.9rem',
  },
};

export default Footer;
